<template>
  <div>   
    <v-divider></v-divider>  
    <mbs-page-tab 
        :items="PageLinks"/> 
    <v-divider></v-divider>  
    <v-divider></v-divider>  
    <v-divider></v-divider> 
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card >  
                    <mbs-item-manager
                        :table_title="'Manage Companies'"
                        :table_title_icon="'mdi-domain'"
                        :add_vertical_lines="true"
                        :row_click="true"
                        :top_label="false"
                        :table_actions="table_actions"
                        :name_items="NAME_ITEMS"
                        :name_item="NAME_ITEM"
                        :value_items="VALUE_ITEMS"
                        :value_item="VALUE_ITEM"
                        :field_items="FIELD_ITEMS"
                        :header_items="HEADER_ITEMS" 
                        :data_items="DATA_ITEMS" 
                        :path_item="PATH_ITEM" 
                        @SELECT_ITEM_ACTION="ITEM_ACTION"
                        @SELECT_TABLE_ACTION="TABLE_ACTION"
                    />
                </v-card>
            </v-col>  
        </v-layout>
    </v-container>  
  </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.COMPANIES.values+"_PAGE" 
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                CURRENT_TAP:0,

                NAME_ITEMS:DATA.ITEMS.COMPANIES.names,
                NAME_ITEM:DATA.ITEMS.COMPANIES.name,
                VALUE_ITEMS:DATA.ITEMS.COMPANIES.values,
                VALUE_ITEM:DATA.ITEMS.COMPANIES.value, 

                FIELD_ITEMS:[ ], 
                items : [ ], 
                table_actions:[ 
                    // {btn:true,text:"Add Bus Trip",action:"add_item",icon:"mdi-bus"},
                    {text:"Export Json",action:"export_items",icon:"mdi-download"},  
                    {text:"Delete All Errors",action:"delete_all_items",icon:"mdi-delete"},  
                ]
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:this.items,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },  
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },
            //
            PageLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("cpanel",1) 
                return thisLinks
            }, 
            PATH_ITEM(){
                let SERVER_NAME = DATA.APP.SERVER
                return ''+SERVER_NAME+'/'+this.MBS.actions.TEXT_UP(this.NAME_ITEMS+'/') 
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},  
                    {id:0,name:"Key",value:'key',show:true},  
                    {id:0,name:"Name",value:'company_name',show:true},  
                    {id:0,name:"Phone",value:'company_phone_number',show:true},  
                    {id:0,name:"Email",value:'company_email_address',show:true},  
                    {id:0,name:"User Name",value:'user_name',show:true},  
                    {id:0,name:"Created",value:"created_at_",show:true}, 
                    {id:0,name:"Updated",value:"updated_at_",show:false}, 
                    {id:0,name:"action",align:"right",show:true,item:[
                        {name:"View "+this.NAME_ITEM,action:"view_company",icon:"mdi-eye"}, 
                    ]}, 
                ]
            }, 
            DATA_ITEMS(){
                let item = this.$store.getters.getCompanies  
                
                return item
            },   
        },
        methods: {  
            ITEM_ACTION(action,item){
                try {
                    if (action == 'view_company') {
                        this.SELECT_COMPANY(item)
                    }
                     console.log(item,'action');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TABLE_ACTION(action){
                try {
                     console.log(action,'action');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async SELECT_COMPANY(company){
                try {
                    await this.MBS.actions.SELECT_COMPANY(company)
                    this.MBS.actions.go("/")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_COMPANY',
                        page:PAGE_NAME, 
                    })
                }  
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    // setTimeout(() => {
                                    //     this.input.loading = true 
                                    //     this.$store.dispatch("fi_add",{
                                    //         action:this.VALUE_ITEM,
                                    //         path:this.PATH_ITEM,
                                    //         data:action.data,
                                    //         us:this.us
                                    //     })
                                    // }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                            let addingItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_ITEM
                            })
                            let updatingItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_ITEM
                            })  
                            let deletingItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEM
                            })
                            let deletingAllItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEMS
                            })
                             

                            if (gettingItems) {
                                this.PAGE_LOAD =false
                            }else{
                                this.PAGE_LOAD =true
                            }

                           

                            if (addingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (updatingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (deletingItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                            if (deletingAllItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }

                             
                        }else{
                            this.PAGE_LOAD =true
                        }

                        //ADD ITEM RESPONSE 
                        
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>